import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Card, Button} from 'antd';
import {RadarChartOutlined} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faStar, faPencilAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { MainHeader, Section } from '../common';
import Image from '../img/home1.jpg';

const Home = () => {
  return (
    <>
      <MainHeader />
      <Section className="main-info">
        <Col xs={24}>
          <Card>
            <Row gutter={24}>
              <Col xs={24} sm={12} md={6}>
                <Link to="/masters/"><FontAwesomeIcon className="icon primary" icon={faGraduationCap} /></Link>
                <Link to="/masters/"><h4>Магистри</h4></Link>
                
                <p>За всички икономически специалности.</p>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Link to="/bachelores/"><FontAwesomeIcon className="icon primary" icon={faThumbsUp} /></Link>
                <Link to="/bachelores/"><h4>Бакалаври</h4></Link>
                <p>За всички икономически специалности.</p>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Link to="/students/"><FontAwesomeIcon className="icon primary" icon={faPencilAlt} /></Link>
                <Link to="/students/"><h4>Ученици</h4></Link>
                <p>Математика от 1 до 12 клас, Excel и PowerPoint.</p>
              </Col>
              <Col xs={24} sm={12}  md={6}>
                <Link to="/masters/"><FontAwesomeIcon className="icon primary" icon={faStar} /></Link>
                <Link to="/masters/"><h4>Дистанционно обучение</h4></Link>
                <p>Пълното съдействие в процеса на дистанционно обучение.</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Section>
      <Section className="overlap-cols">
        <Col md={12} className="left-col">
          <h5><Link to="about-us/">За нас</Link></h5>
          <h2>Дългогодишни <em style={{fontFamily: "Playfair Display&quot"}}>традиции</em> и <em style={{fontFamily: "&quot;Playfair Display&quot"}}>опит</em>!</h2>
          <p>Екипът ни предлага професионални услуги в областта на разработването на теми на <Link to="/masters/">магистърски тези</Link>, <Link to="/bachelores/"><storng>дипломни и курсови работ</storng></Link>и, <strong>реферати</strong>, есета, казуси, <Link to="/students/" className="no-style">задачи</Link>, въпроси, отчети за стажове и др., в икономическата област.</p>
        </Col>
        <Col md={12} className="right-col">
          <div className="background" style={{backgroundImage: `url(${Image})`}}/>
        </Col>
      </Section>
      <Section background style={{padding: "5% 0"}}>
        <Col xs={24} align="center" style={{marginBottom: "2em"}}>
          <h2>С какво можем да сме полезни?</h2>
          <p className="subtext">Екипът ни предлага професионални услуги в областта на разработването на теми на <Link to="/masters/" className="no-style"><strong>магистърски тези</strong></Link>, <Link to="/bachelores/" className="no-style"><strong>дипломни и курсови работи</strong></Link>, реферати, есета, казуси, <Link to="/students/" className="no-style">задачи</Link>, въпроси, отчети за стажове и др., в икономическата област.</p>
        </Col>
        <Col xs={24}>
          <Row className="stretched-cols home-cards" gutter={[24, 24]}>
            <Col xs={24} md={12} xl={6}>
              <Card align="center">
                <RadarChartOutlined className="icon secondary round" />
                <h4>ДИПЛОМНИ И КУРСОВИ РАБОТИ</h4>
                <p>Гарантирана уникалност, професионализъм и конфиденциалност.</p>
                <Link to="/contacts/"><Button className="secondary-btn">Свържете се с нас</Button></Link>
              </Card>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Card align="center">
                <RadarChartOutlined className="icon secondary round" />
                <h4>КАЗУСИ И ТЕСТОВЕ</h4>
                <p>Гарантирано качество за редовно и дистанционно обучени.</p>
                <Link to="/contacts/"><Button className="secondary-btn">Свържете се с нас</Button></Link>
              </Card>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Card align="center">
                <RadarChartOutlined className="icon secondary round" />
                <h4>ДИСТАНЦИОННО ОБУЧЕНИЕ</h4>
                <p>Пълно съдействие в процеса на Вашето дистанционно обучение.</p>
                <Link to="/contacts/"><Button className="secondary-btn">Свържете се с нас</Button></Link>
              </Card>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Card align="center">
                <RadarChartOutlined className="icon secondary round" />
                <h4>EXCEL И POWER POINT</h4>
                <p>Изготвяне на отделни задания на Excel и Power Point.</p>
                <Link to="/contacts/"><Button className="secondary-btn">Свържете се с нас</Button></Link>
              </Card>
            </Col>
          </Row>
        </Col>
      </Section>
    </>
  );
};

export default Home;